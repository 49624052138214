import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { initializeApp } from 'firebase/app'

import { createMetaManager } from 'vue-meta'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import axios from 'axios'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import '@fortawesome/fontawesome-free/css/all.css'
import i18n from './i18n'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import BootstrapVue from 'bootstrap-vue-3'
import io from 'socket.io-client'
import { initInlineSvg } from "@/core/plugins/inline-svg";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import VueGtag from 'vue-gtag';
import moment from 'moment';

const firebaseConfig = {
  apiKey: "AIzaSyDmsXbrPt474s96rpNOHPBAu7-LXwB2CYQ",
  authDomain: "growstudio-ec1e2.firebaseapp.com",
  projectId: "growstudio-ec1e2",
  storageBucket: "growstudio-ec1e2.appspot.com",
  messagingSenderId: "1033962269325",
  appId: "1:1033962269325:web:e2c30822f38db93651d0c4",
  measurementId: "G-Y4C97PX5WS"
};

axios.interceptors.request.use(request => {
  const account = JSON.parse(window.localStorage.getItem('user'));
  const isApiUrl = request.url.startsWith(process.env.VUE_APP_APIURL);

  if (account && isApiUrl) {
      request.headers.common.Authorization = `Bearer ${account.token}`;
  } 

  return request;
})

initializeApp(firebaseConfig);

const app = createApp(App).use(i18n);

app.config.globalProperties.$areUserRolesAllowed = function(allowedUserRoles) {
  let allowAccess = false;
  let userRoles = JSON.parse(window.localStorage.getItem('user')).roles;
  console.log("User Roles");

  if (!allowedUserRoles || allowedUserRoles.length === 0) {
    return true;
  }

  for (const role of userRoles) {
    for (const allowedRole of allowedUserRoles) {
      const userActualRole = role.user_roles?.organisationId ? 'org' + role.title.toLowerCase() : role.title.toLowerCase();
      if (userActualRole === allowedRole.toLowerCase()) {
        allowAccess = true;
      }
    }
  }

  return allowAccess;
};

app.config.globalProperties.$hasRole = function(role) {
  return this.$areUserRolesAllowed([role]);
};

app.config.globalProperties.$filters = {
  datePipe(timestamp) {
    return moment(String(timestamp)).format('DD/MM/YYYY, h:mm:ss a');
  },

  uppercase(text) {
    if (!text) {
      return;
    }
    return text.toUpperCase();
  },

  translatePipe(element) {
    if (!element) {
      return 'No translation found';
    }

    if (element.translated_title) {
      if (element.translated_title[localStorage.getItem('lang')]) {
        return element.translated_title[localStorage.getItem('lang')];
      }
      return element.translated_title.en;
    } else {
      return element.title;
    }
  },

  translateDescription(element) {
    if (!element) {
      return 'No translation found';
    }

    if (element.translated_description) {
      if (element.translated_description[localStorage.getItem('lang')]) {
        return element.translated_description[localStorage.getItem('lang')];
      }
      return element.translated_description.en;
    } else {
      return element.description;
    }
  },
}

app.config.globalProperties.$translateContent = function (contentInput) {
  const lang = localStorage.getItem('lang'); // Get the current language code from localStorage
  
  // Check if contentInput is an array or a single object
  let isArray = Array.isArray(contentInput);
  let contentArray = isArray ? contentInput : [contentInput];

  let translatedContent = contentArray.map(content => {
    // Identify the correct language property name
    const languagePropertyName = ['course_language', 'lesson_language', 'quiz_language']
      .find(propertyName => content.hasOwnProperty(propertyName));

    // If a language property name is found, proceed with translation
    if (languagePropertyName) {
      const languageData = content[languagePropertyName].find(language => language.languageCode === lang);

      if (languageData) {
        // Create a shallow copy to avoid mutating the original content
        let translated = { ...content };
        // Iterate over the languageData properties and replace them in the translated object
        Object.keys(languageData).forEach(key => {
          // Assuming you want to skip replacing the 'languageCode' itself
          if (key !== 'languageCode' && key !== 'id') {
            translated[key] = languageData[key];
          }
        });
        return translated;
      }
    }

    return content; // Return the content object as is if no translation is done
  });

  // Return the processed content directly if it was an array, or unwrap it if it was a single object
  return isArray ? translatedContent : translatedContent[0];
}

app.config.globalProperties.$navigateTo = function(route, forceReload = false) {
  if (route.startsWith('/')) {
    this.$router.push({ path: route }).then(() => {
      forceReload ? this.$router.go() : null;
    });
  } else {
      const basePath = this.$route.matched[0].path;
      this.$router.push({ path: `${basePath}/${route}`.replace(/\/\/+/g, '/') }).then(() => {
        forceReload ? this.$router.go() : null;
      })
  }

  window.scrollTo(0, 0);
};

app.config.globalProperties.$apiUrl = process.env.VUE_APP_APIURL;
app.config.globalProperties.$languages = [
  { title: 'English', code: 'en', i18n: 'general.languages.en' },
  { title: 'Chinese', code: 'zh', i18n: 'general.languages.zh' },
  { title: 'Malay', code: 'ms', i18n: 'general.languages.ms' },
  { title: 'Tamil', code: 'ta', i18n: 'general.languages.ta' },
  { title: 'Burmese', code: 'my', i18n: 'general.languages.my' },
  { title: 'Sinhalese', code: 'si', i18n: 'general.languages.si' },
  { title: 'Bengali', code: 'bn', i18n: 'general.languages.bn' },
];
app.config.globalProperties.$socketio = io(`https://api.${process.env.VUE_APP_EXTERNALHANDLE}/`);

app.use(store);
app.use(router);
app.use(createMetaManager());
app.use(VueToast);
app.use(BootstrapVue);
app.use(VueSidebarMenu);
app.use(VueTelInput)
app.use(i18n);
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDmsXbrPt474s96rpNOHPBAu7-LXwB2CYQ',
  },
});
app.use(VueGtag, {
  config: { id: 'UA-203455420-1' },
  // enabled: process.env.NODE_ENV === 'production',
}, router);
initInlineSvg(app);
app.mount('#app');