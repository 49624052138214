import axios from 'axios'
import UserService from './UserService';

class AnswerService {

    createAnswer(data) {
        return axios.post(`${process.env.VUE_APP_APIURL}/answers`, data);
    }
    mark(data) {
        return axios.post(`${process.env.VUE_APP_APIURL}/answers/mark`, data);
    }
    getAnswers() {
        return axios.get(`${process.env.VUE_APP_APIURL}/answers`);
    }
    getUserAnswers() {
        return axios.get(`${process.env.VUE_APP_APIURL}/answers?userId=${UserService.getUserState().id}`);
    }
    getUserAnswersByQuiz(quizId) {
        return axios.get(`${process.env.VUE_APP_APIURL}/answers?quizId=${quizId}&userId=${UserService.getUserState().id}`);
    }
    getVolunteerAnswers() {
        return axios.get(`${process.env.VUE_APP_APIURL}/answers?assignedToId=${UserService.getUserState().id}`);
    }
    getAnswer(id) {
        return axios.get(`${process.env.VUE_APP_APIURL}/answers/${id}`);
    }
    updateAnswer(id, data) {
        return axios.put(`${process.env.VUE_APP_APIURL}/answers/${id}`, data);
    }
    deleteAnswer(id) {
        return axios.delete(`${process.env.VUE_APP_APIURL}/answers/${id}`);
    }

  }


export default new AnswerService();
