<template>
  <div :style="userGroupStyles">
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content}` : `GROWSTUDIO` }}</template>
    </metainfo>
    <router-view />
  </div>
</template>

<script>
import AuthService from './services/UserService';
import { useMeta } from 'vue-meta';
export default {
  data () {
    return {
      userGroup: AuthService.getUserState()?.group || ''
    }
  },
  setup() {
    useMeta({
      title: 'Home',
      meta: [
        {
          name: 'description',
          content: 'Providing everyone and anyone with the tools and resources to empower themselves at no cost.',
        }
      ],
      htmlAttrs: {
        lang: 'en',
        amp: true,
      },
    })
  },
  computed: {
    userGroupStyles() {
      if (!this.userGroup || this.userGroup === '') {
        return {
          '--primary-color': '#81b401',
          '--secondary-color': '#DDEBC3',
          '--tertiary-color': '#000000',
          '--background-color': '#F3F7EB',
          '--hover-color': '#216e1d',
        }
      } else if (this.userGroup === 'migrant') {
        return {
          '--primary-color': '#ff9800', // Orange
          '--secondary-color': '#8C4F1A',
          '--tertiary-color': '#000000', 
          '--background-color': '#F3F7EB',
          '--hover-color': '#c66900', // Dark Orange
        }
      } else if (this.userGroup === 'elderly') {
        return {
          '--primary-color': '#607d8b', // Blue Grey
          '--secondary-color': '#8C4F1A',
          '--tertiary-color': '#000000',
          '--background-color': '#F3F7EB',
          '--hover-color': '#455a64', // Dark Blue Grey
        }
      }
    },
  },
  methods: {
  },
  mounted() {
    let externalScriptJS = document.createElement("script");
    externalScriptJS.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"
    );

    externalScriptJS.setAttribute(
      "integrity",
      "sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4"
    )

    externalScriptJS.setAttribute("crossorigin", "anonymous");
    
    document.head.appendChild(externalScriptJS);

    if (!localStorage.getItem("lang")) {
      var language = navigator.language || navigator.userLanguage;
      language = language.split("-")[0];
      localStorage.setItem("lang", language);
    }
  },
};
</script>

<style src="@/assets/css/bootstrap.css"></style>
<style src="@/assets/css/global.css"></style>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
</style>