import { createMemoryHistory, createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import LessonService from '@/services/LessonService'
import UserService from '../services/UserService'
import AnswerService from '../services/AnswerService'

const routes = [
  {
    path: '/',
    name: 'Website',
    component: () => import('../views/Website.vue'),
    children: [
      {
        path: '',
        name: 'WebsiteHome',
        component: () => import('../views/Website/Home.vue'),
      },{
        path: 'courses',
        name: 'WebsiteCourses',
        component: () => import('../views/Website/Courses.vue'),
      },
      {
        path: 'courses/:courseId',
        name: 'WebsiteCourse',
        component: () => import('../views/Website/Course.vue'),
      },
      {
        path: 'lessons/:lessonId',
        name: 'WebsiteLessons',
        component: () => import('../views/Website/Lessons.vue'),
        meta: {
          requiresEnrolled: true,
        }
      },
      {
        path: 'quizzes/:quizId',
        name: 'WebsiteQuizzes',
        component: () => import('../views/Website/Quiz.vue'),
        meta: {
          requiresQuizEligibility: true,
        }
      },
      {
        path: 'profile',
        name: 'WebsiteProfile',
        component: () => import('../views/Website/Profile.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'about',
        name: 'WebsiteAbout',
        component: () => import('../views/Website/About.vue'),
      },
      {
        path: 'volunteer',
        name: 'WebsiteVolunteer',
        component: () => import('../views/Website/Volunteer.vue'),
      },
      {
        path: 'login',
        name: 'WebsiteLogin',
        component: () => import('../views/Website/Login.vue'),
      },
      {
        path: 'invitation/:invitationId',
        name: 'WebsiteInvitation',
        component: () => import('../views/Website/Invitation.vue'),
      }
    ]
  },
  {
    path: '/old',
    name: 'Nav',
    component: () => import('../views/Nav.vue'),
    children: [
      // {
      //   path: '/',
      //   name: 'Default',
      //   component: () => import('../views/Navigation/Home.vue'),
      // },
      {
        path: '/home',
        name: 'Home',
        component: () => import('../views/Navigation/Home.vue'),
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/Navigation/About.vue'),
      },
      {
        path: '/categories',
        name: 'Categories',
        component: () => import('../views/Navigation/Categories.vue')
      },
      {
        path: '/shortcourses',
        name: 'Short Courses',
        component: () => import('../views/Navigation/ShortCourses.vue')
      },
      {
        path: '/courses/:courseId',
        name: 'Courses',
        component: () => import('../views/Navigation/Courses.vue')
      },
      {
        path: '/lessons/:lessonId',
        name: 'Lessons',
        component: () => import('../views/Navigation/Lessons.vue'),
        meta: {
          requiresEnrolled: true,
        }
      },
      {
        path: '/character-learner',
        name: 'CharacterLearner',
        component: () => import('../views/Navigation/CharacterLearner.vue'),
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('../views/auth/Register.vue')
      },
      {
        path: '/quizzes/:quizId',
        name: 'Quizzes',
        component: () => import('../views/Navigation/Quizzes.vue'),
        meta: {
          requiresQuizEligibility: true,
        }
      },
      {
        path: '/feedbacks/:feedbackId',
        name: 'Feedbacks',
        component: () => import('../views/Navigation/Feedbacks.vue')
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Navigation/Contact.vue'),
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('../views/Navigation/Profile.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('../views/auth/Login.vue')
      },
      {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        component: () => import('../views/auth/ResetPassword.vue')
      },
      {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('../views/Navigation/PrivacyPolicy.vue')
      },
      {
        path: '/mobilesignup',
        name: 'Mobile Signup',
        component: () => import('../views/auth/MobileSignup.vue'),
      },
      {
        path: '/checkout',
        name: 'Checkout',
        component: () => import('../views/Navigation/Checkout.vue'),
      },
      {
        path: '/checkout-success',
        name: 'CheckoutSuccess',
        component: () => import('../views/Navigation/CheckoutSuccess.vue'),
      },
      {
        path: '/test',
        name: 'Test',
        component: () => import('../views/Navigation/Test.vue'),
      },
      // {
      //   path: '/invitation/:invitationId',
      //   name: 'Invitation',
      //   component: () => import('../views/Navigation/Invitation.vue'),
      // },
      {
        path: '/leaderboard',
        name: 'Leaderboard',
        component: () => import('../views/Navigation/Leaderboard.vue'),
      }
    ]
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresRoles: ['admin', 'moderator', 'orgadmin'],
    },
    children: [
      {
        path: '',
        name: 'DashboardHome',
        component: () => import('../views/Dashboard/DashboardHome.vue'),
        meta: { requiresRoles: ['admin', 'moderator', 'orgadmin'], },
      },
      {
        path: '/user',
        name: 'User',
        component: () => import('../views/Dashboard/users/User.vue'),
        meta: { requiresRoles: ['admin', 'orgadmin'], },
      },
      {
        path: '/user/edit/:userId',
        name: 'UserEdit',
        component: () => import('../views/Dashboard/users/UserEdit.vue'),
        meta: { requiresRoles: ['admin', 'orgadmin'], },
      },
      {
        path: '/volunteers',
        name: 'Volunteer',
        component: () => import('../views/Dashboard/users/Volunteer.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/file',
        name: 'File',
        component: () => import('../views/Dashboard/users/File.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/notification',
        name: 'Notification',
        component: () => import('../views/Dashboard/users/Notification.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/log',
        name: 'Log',
        component: () => import('../views/Dashboard/users/Log.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/category',
        name: 'Category',
        component: () => import('../views/Dashboard/courses/Category.vue'),
        meta: { requiresRoles: ['admin', 'moderator',], },
      },
      {
        path: '/category/edit/:categoryId',
        name: 'CategoryEdit',
        component: () => import('../views/Dashboard/courses/CategoryEdit.vue'),
        meta: { requiresRoles: ['admin', 'moderator',], },
      },
      {
        path: '/course',
        name: 'Course',
        component: () => import('../views/Dashboard/courses/Course.vue'),
        meta: { requiresRoles: ['admin', 'moderator', 'orgadmin'], },
      },
      {
        path: '/course/edit/:courseId',
        name: 'CourseEdit',
        component: () => import('../views/Dashboard/courses/CourseEdit.vue'),
        meta: { requiresRoles: ['admin', 'moderator', 'orgadmin'], },
      },
      {
        path: '/lesson',
        name: 'Lesson',
        component: () => import('../views/Dashboard/courses/Lesson.vue'),
        meta: { requiresRoles: ['admin', 'moderator', 'orgadmin'], },
      },
      {
        path: '/lesson/create',
        name: 'LessonCreate',
        component: () => import('../views/Dashboard/courses/LessonCreate.vue'),
        meta: { requiresRoles: ['admin', 'moderator', 'orgadmin'], },
      },
      {
        path: '/lesson/edit/:lessonId',
        name: 'LessonEdit',
        component: () => import('../views/Dashboard/courses/LessonEdit.vue'),
        meta: { requiresRoles: ['admin', 'moderator', 'orgadmin'], },
      },
      {
        path: '/character',
        name: 'Character',
        component: () => import('../views/Dashboard/courses/Character.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/quiz',
        name: 'Quiz',
        component: () => import('../views/Dashboard/quizzes/Quiz.vue'),
        meta: { requiresRoles: ['admin', 'moderator', 'orgadmin'], },
      },
      {
        path: '/quiz/edit/:quizId',
        name: 'Question',
        component: () => import('../views/Dashboard/quizzes/Question.vue'),
        meta: { requiresRoles: ['admin', 'moderator', 'orgadmin'], },
      },
      {
        path: '/answer',
        name: 'Answer',
        component: () => import('../views/Dashboard/quizzes/Answer.vue'),
        meta: { requiresRoles: ['admin', 'moderator', 'orgadmin'], },
      },
      {
        path: '/answer/edit/:answerId',
        name: 'Marking',
        component: () => import('../views/Dashboard/quizzes/AnswerMark.vue'),
        meta: { requiresRoles: ['admin', 'tutor', 'moderator', 'orgadmin', 'orgtutor'], },
      },
      {
        path: '/answer/view/:answerId',
        name: 'AnswerView',
        component: () => import('../views/Dashboard/quizzes/AnswerView.vue'),
      },
      {
        path: '/feedback',
        name: 'Feedback',
        component: () => import('../views/Dashboard/feedbacks/Feedback.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/feedback/edit/:feedbackId',
        name: 'FeedbackEdit',
        component: () => import('../views/Dashboard/feedbacks/FeedbackEdit.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/response',
        name: 'Response',
        component: () => import('../views/Dashboard/feedbacks/Response.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/response/edit/:feedbackId/:userId',
        name: 'ResponseEdit',
        component: () => import('../views/Dashboard/feedbacks/ResponseEdit.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/location',
        name: 'Location',
        component: () => import('../views/Dashboard/locations/Location.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/email',
        name: 'Email',
        component: () => import('../views/Dashboard/users/Email.vue'),
        meta: { requiresRoles: ['admin'], },
      },

      // School Related Routes
      {
        path: '/school',
        name: 'School',
        component: () => import('../views/Dashboard/schools/School.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/school/edit/:schoolId',
        name: 'SchoolEdit',
        component: () => import('../views/Dashboard/schools/SchoolEdit.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/class',
        name: 'Class',
        component: () => import('../views/Dashboard/schools/Class.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/teacher',
        name: 'Teacher',
        component: () => import('../views/Dashboard/schools/Teacher.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/student',
        name: 'Student',
        component: () => import('../views/Dashboard/schools/Student.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/signupcode',
        name: 'SignupCode',
        component: () => import('../views/Dashboard/schools/SignupCode.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/signupcode/edit/:signupCodeId',
        name: 'SignupCodeEdit',
        component: () => import('../views/Dashboard/schools/SignupCodeEdit.vue'),
        meta: { requiresRoles: ['admin'], },
      },

      // Stripe Related Routes
      {
        path: '/coupon',
        name: 'Coupon',
        component: () => import('../views/Dashboard/stripe/Coupon.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/coupon/edit/:couponId',
        name: 'CouponEdit',
        component: () => import('../views/Dashboard/stripe/CouponEdit.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/payment',
        name: 'Payment',
        component: () => import('../views/Dashboard/stripe/Payment.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/promocode',
        name: 'PromoCode',
        component: () => import('../views/Dashboard/stripe/PromoCode.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/promocode/edit/:promoCodeId',
        name: 'PromoCodeEdit',
        component: () => import('../views/Dashboard/stripe/PromoCodeEdit.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/receipt',
        name: 'Receipt',
        component: () => import('../views/Dashboard/stripe/Receipt.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/organisation',
        name: 'Organisation',
        component: () => import('../views/Dashboard/users/Organisation.vue'),
        meta: { requiresRoles: ['admin'], },
      },
      {
        path: '/signupcode',
        name: 'SignupCode',
        component: () => import('../views/auth/SignupCode.vue'),
        meta: { requiresRoles: ['admin', 'orgadmin'], },
      },
      {
        path: '/signupcode/:id',
        name: 'SignupCodeEdit',
        component: () => import('../views/auth/SignupCodeEdit.vue'),
        meta: { requiresRoles: ['admin', 'orgadmin'], },
      }
    ]
  },

  {
    path: '/mobilesignup',
    name: 'Mobile Signup',
    component: () => import('../views/auth/MobileSignup.vue'),
  },
  {
    path: '/mobilesignupsuccess',
    name: 'Mobile Signup Success',
    component: () => import('../views/auth/MobileSignupSuccess.vue'),
  }
] 

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const userState = JSON.parse(window.localStorage.getItem('user'));

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (userState) {
      return next()
    } else {
      next('/login')
    }
  } else if (to.matched.some((record) => record.meta.requiresRoles)) {
    if (userState && (areUserRolesAllowed(to.matched[to.matched.length-1].meta.requiresRoles))) {
      return next()
    } else { }
  } else if (to.matched.some((record) => record.meta.requiresEnrolled)) {
    var lesson = await LessonService.getLesson(to.params.lessonId).then((response) => {
    }).catch((error) => {
      console.log("Error", error);
    });
    if (!lesson) {
      next();
    }

    if (await UserService.canAccessLesson(lesson.data)) {
      next();
    } else {
      alert("You must be enrolled to view this page");
      next(from.fullPath);
    }
  } else if (to.matched.some((record) => record.meta.requiresQuizEligibility)) {
    var userAnswers = await AnswerService.getUserAnswersByQuiz(to.params.quizId).catch(e => {
      console.log(e);
    });
    if (UserService.canAccessQuiz(userAnswers.data)) {
      next();
    } else {
      alert("You are not eligible to take this quiz");
      next(from.fullPath);
    }
  } else {
    next()
  }
})

// Auth
export function areUserRolesAllowed(allowedUserRoles) {
  let allowAccess = false;
  let userRoles = JSON.parse(window.localStorage.getItem('user')).roles;

  if (!allowedUserRoles || allowedUserRoles.length === 0) {
    return true;
  }

  for (const role of userRoles) {
    // If user is an admin, allow access to all pages
    // if (role.title.toLowerCase() === 'admin' && !role.user_roles?.organisationId) {
    //   return true;
    // }

    for (const allowedRole of allowedUserRoles) {
      const userActualRole = role.user_roles?.organisationId ? 'org' + role.title.toLowerCase() : role.title.toLowerCase()
      if (userActualRole === allowedRole.toLowerCase()) {
        allowAccess = true;
      }
    }
  }

  return allowAccess;
}

export default router
