export default {
  "general": {
    "button": {
      "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আরও জানুন"])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের সাথে যোগাযোগ করুন"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পরবর্তী"])},
      "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আগের"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ফিরে"])}
    },
    "languages": {
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইংরেজি"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["চাইনিজ"])},
      "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মলয়"])},
      "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["তামিল"])},
      "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বার্মিজ"])},
      "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিংহলী"])},
      "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বাংলা"])}
    }
  },
  "toasts": {
    "logout": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি কি নিশ্চিত আপনি লগ আউট করতে চান?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি সফলভাবে লগ আউট করা হয়েছে."])}
    },
    "message": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বার্তা সফলভাবে পাঠানো হয়েছে৷"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনার বার্তা পাঠানোর সময় একটি ত্রুটি ছিল৷"])},
      "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনার নাম লিখুন."])},
      "email_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনার ইমেল লিখুন."])},
      "email_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["একটি বৈধ ইমেল লিখুন."])},
      "message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["একটি বার্তা লিখুন."])},
      "password_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাসওয়ার্ড মেলে না।"])}
    },
    "login": {
      "region_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অনুগ্রহ করে নিশ্চিত করুন যে একটি অঞ্চল নির্বাচন করা হয়েছে এবং আপনি একটি বৈধ ফোন নম্বর প্রবেশ করেছেন৷"])},
      "verification_code_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমরা আপনার নম্বরে যাচাইকরণ কোড পাঠাতে অনুগ্রহ করে অপেক্ষা করুন।"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["স্বাগতম! আমরা আপনাকে এখানে পেয়ে উত্তেজিত"])},
      "welcome_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সফলভাবে লগ ইন, ফিরে স্বাগতম"])},
      "ensure_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি একটি বৈধ ইমেল এবং পাসওয়ার্ড প্রবেশ করেছেন নিশ্চিত করুন."])},
      "ensure_valid_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি একটি বৈধ নাম প্রবেশ করানো হয়েছে তা নিশ্চিত করুন."])},
      "signup_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি সফলভাবে সাইন আপ করেছেন।"])},
      "password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাসওয়ার্ড সফলভাবে রিসেট করা হয়েছে।"])},
      "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["চালিয়ে যেতে আপনার পাসওয়ার্ড লিখুন"])}
    },
    "contact": {
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এক বা একাধিক ইনপুট ক্ষেত্র মানদণ্ড পূরণ করে না।"])},
      "email_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনার ইমেল সফলভাবে পাঠানো হয়েছে."])}
    },
    "course": {
      "success_enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি সফলভাবে এই কোর্সে নথিভুক্ত করেছেন।"])}
    },
    "feedback": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["প্রতিক্রিয়া সফলভাবে জমা দেওয়া হয়েছে।"])}
    },
    "quiz": {
      "first_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি প্রথম প্রশ্নে আছেন!"])},
      "fill_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি এগিয়ে যাওয়ার আগে উত্তর অবশ্যই পূরণ করতে হবে!"])},
      "last_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি শেষ প্রশ্নে আছেন, আপনি কি আপনার উত্তর জমা দেওয়ার বিষয়ে নিশ্চিত?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কুইজ সফলভাবে জমা দেওয়া হয়েছে।"])}
    }
  },
  "form": {
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নাম"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পুরো নাম"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইমেইল"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ফোন নম্বর"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বার্তা"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোম্পানির নাম"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["জাতীয়তা"])},
      "nationality_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিঙ্গাপুরের"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ভূমিকা"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এনআরআইসি"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাসওয়ার্ড"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাসওয়ার্ড নিশ্চিত করুন"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অঞ্চল"])},
      "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ওয়ান টাইম ভেরিফিকেশন কোড (OTP)"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বয়স"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["গ্রুপ"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ডিফল্ট"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বৃদ্ধ"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অভিবাসী"])}
      },
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লিঙ্গ"])},
      "genderOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লিঙ্গ নির্বাচন করুন"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পুরুষ"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মহিলা"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অন্যান্য"])}
      },
      "englishProficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইংরেজি দক্ষতা"])},
      "englishProficiencyOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দক্ষতা নির্বাচন করুন"])},
        "very-poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["খুবই দরিদ্র"])},
        "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দরিদ্র"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["গড়"])},
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ভাল"])},
        "very-good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["খুব ভালো"])}
      },
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের স্বেচ্ছাসেবক হতে 5 মিনিট সময় আছে?"])},
      "volunteer_flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের ডিজিটাল স্বেচ্ছাসেবকরা বিশ্বের যেকোন স্থান থেকে আমাদের সাহায্য করে। আমাদের সিস্টেম শুধুমাত্র তখনই আপনাকে অবহিত করে যখন আপনি বিনামূল্যে থাকেন এবং আপনাকে আপনার অতিরিক্ত সময় টিউটরিং বা আমাদের অভিবাসী পরিবারের জন্য নতুন ক্লাস তৈরি করার জন্য ব্যয় করার অনুমতি দেয়।"])}
    },
    "button": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["জমা দিন"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাঠান"])},
      "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["প্রবেশ করুন"])},
      "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নথিভুক্ত করুন"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপডেট"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মুছে দিন"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["চালিয়ে যান"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সাইন ইন করুন"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নিবন্ধন করুন"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["রিসেট করুন"])},
      "get_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP পান"])}
    }
  },
  "home": {
    "title": {
      "online_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অনলাইন কোর্স"])},
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিনামূল্যে"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["প্রত্যেককে এবং যে কাউকে সরঞ্জাম এবং সংস্থান সরবরাহ করা"])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিনা মূল্যে নিজেদের ক্ষমতায়ন করুন।"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আরও দেখুন"])},
      "get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["শুরু করুন"])}
    },
    "cards": {
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মাল্টি-ভাষা সমর্থিত"])},
      "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোর্সগুলি আপনাকে বৃদ্ধি করতে সাহায্য করবে"])},
      "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিনামূল্যে কোর্স, চিরতরে"])}
    },
    "banner_1": {
      "our_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের কাজ"])},
      "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দক্ষতা"])},
      "development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["উন্নয়ন"])},
      "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["প্রশিক্ষণ"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের লক্ষ্য হল বিদেশী কর্মীদের তাদের জ্ঞানীয় দক্ষতার উন্নতিতে সাহায্য করা যাতে সিঙ্গাপুরে আরও শক্তিশালী ভূমিকা নেওয়া যায় এবং তাদের নিজেদের উন্নতি ও বিকাশের আরও ভাল সুযোগ দেওয়া।"])},
      "point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অনলাইন শিক্ষণ শেখা"])},
      "point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দক্ষতা উন্নয়ন"])},
      "point_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["জীবন উন্নতি"])}
    },
    "banner_2": {
      "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বৃদ্ধি"])},
      "your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনার"])},
      "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের সাথে ভবিষ্যত!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনার দক্ষতা উন্নত করতে সাহায্য করার জন্য উপযোগী কোর্স।"])},
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট প্রশিক্ষক"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোর্স উপলব্ধ"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিভিন্ন ভাষা"])}
    },
    "banner_3": {
      "updated_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপডেট করা কোর্স"])},
      "our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের"])},
      "latest_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সর্বশেষ কোর্স"])}
    },
    "banner_4": {
      "taking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নিচ্ছেন"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["শিক্ষা"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পরবর্তীতে"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["প্রযুক্তির সাথে স্তর"])}
    }
  },
  "v2home": {
    "section1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোর্সগুলি আপনাকে বৃদ্ধি করতে সাহায্য করবে"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিনামূল্যে অনলাইন কোর্স"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["প্রত্যেককে এবং যে কাউকে নিজেদের ক্ষমতায়নের জন্য সরঞ্জাম এবং সংস্থান সরবরাহ করা"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোন খরচে"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোর্স শুরু করুন"])}
    },
    "section2": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমরা আপনাকে সাহায্য করব"])},
      "subtitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দক্ষতা"])},
      "subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["উন্নয়ন"])},
      "subtitle_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এবং প্রশিক্ষণ"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের লক্ষ্য হল অভিবাসী কর্মীদের দক্ষতা এবং দক্ষতা বৃদ্ধি করে তাদের জীবনকে উন্নীত করা, যার ফলে বর্ধিত সুযোগ এবং উন্নত জীবিকার দ্বার উন্মুক্ত করা। আমরা অভিবাসী কর্মীদের আত্মবিশ্বাস এবং দক্ষতার সাথে সম্ভাবনার বিশ্বে নেভিগেট করতে সক্ষম করতে প্রতিশ্রুতিবদ্ধ।"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আরও জানুন"])}
    },
    "section3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট প্রশিক্ষক"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোর্স উপলব্ধ"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিভিন্ন ভাষা"])}
    },
    "section4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের সর্বশেষ কোর্স"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিস্তারিত দেখুন"])},
      "more_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আরও কোর্স"])}
    }
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের সম্পর্কে"])},
    "banner_1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোর্সগুলি আপনাকে বৃদ্ধি করতে সাহায্য করবে"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের সাথে বেড়ে উঠুন"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["গ্রোস্টুডিওতে স্বাগতম"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seah Kim Cheok Construction Co. (Pte) Ltd দ্বারা নির্মিত এবং Grow Education দ্বারা চালিত, Grow Studio হল একটি বিনামূল্যের অনলাইন প্ল্যাটফর্ম যা বিদেশী কর্মীদের শিখতে ও বৃদ্ধি পেতে সহায়তা করার জন্য নিবেদিত। সিঙ্গাপুর ম্যানেজমেন্ট ইউনিভার্সিটি এবং Superink Pte Ltd দ্বারা যৌথভাবে সমর্থিত, আমরা বিদেশী কর্মীদের ইংরেজি, স্বাস্থ্যসেবা, ফিনান্স এবং আরও অনেক কিছুতে প্রয়োজনীয় দক্ষতা অর্জনে সাহায্য করার লক্ষ্য রাখি, তাদের কর্মজীবন এবং জীবনধারা উন্নত করতে তাদের ক্ষমতায়ন করি।"])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের কোর্সগুলি একাধিক ভাষায় অফার করা হয় এবং কার্যকর শিক্ষা নিশ্চিত করতে মূল্যায়ন অন্তর্ভুক্ত করে। চলমান COVID-19 মহামারীর আলোকে, গ্রো স্টুডিও—গ্রো এডুকেশন দ্বারা সক্ষম—প্রথাগত ক্লাসরুম সেটিংসের সাথে যুক্ত ঝুঁকি ছাড়াই বিদেশী কর্মীদের নতুন দক্ষতা এবং জ্ঞান অর্জনের জন্য একটি নিরাপদ এবং অ্যাক্সেসযোগ্য উপায় প্রদান করে।"])},
      "explore_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এক্সপোর কোর্স"])}
    },
    "banner_2": {
      "desc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের চয়ন করুন"])},
      "explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এই সাইটে দেওয়া প্রতিটি পাঠ এবং ক্লাস বিনামূল্যে। এটি যে কেউ, বিশেষ করে বিদেশী কর্মীদের জন্য উন্মুক্ত।"])},
      "desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের মিশন"])},
      "explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিনা খরচে নিজেদের ক্ষমতায়নের জন্য প্রত্যেককে এবং যেকোনও ব্যক্তিকে সরঞ্জাম এবং সংস্থান প্রদান করুন।"])},
      "desc_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের ভিশন"])},
      "explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের স্থানীয় অভিবাসী সম্প্রদায়কে বৃদ্ধি করতে, তাদের দক্ষতা এবং যোগাযোগের উন্নতি করতে।"])}
    },
    "banner_3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট প্রশিক্ষক"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোর্স উপলব্ধ"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিভিন্ন ভাষা"])}
    },
    "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের দল"])},
    "banner_4": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পরিচয় করিয়ে দিচ্ছে"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের দলের সাথে দেখা করুন"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের দলে বিভিন্ন ব্যাকগ্রাউন্ড এবং দক্ষতার সেট সহ বিশেষজ্ঞদের নিয়ে গঠিত, প্রত্যেকেই তাদের অনন্য দৃষ্টিভঙ্গি টেবিলে নিয়ে আসে।"])},
      "team_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ব্যবস্থাপনা পরিচালক মো"])},
      "team_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["তথ্যপ্রযুক্তি উন্নয়নের পরিচালক ড"])},
      "team_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["প্রশাসনের পরিচালক মো"])},
      "team_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["শিক্ষা পরিচালক মো"])},
      "team_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT DevOps নেতা"])}
    }
  },
  "volunteer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["স্বেচ্ছাসেবক"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আজই একজন স্বেচ্ছাসেবক হিসেবে সাইন আপ করুন এবং আমাদের অভিবাসী কর্মীদের তাদের শিক্ষায় সহায়তা করুন"])},
    "form_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এই ফর্মটি পূরণ করুন এবং আমরা শীঘ্রই আপনার কাছে ফিরে আসব।"])},
    "form_select_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অনুগ্রহ করে এমন ভাষা নির্বাচন করুন যা আপনি স্বাচ্ছন্দ্য বোধ করেন"])}
  },
  "categories": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোর্স"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের সাথে বাড়াতে আমাদের ক্লাসগুলি অন্বেষণ করুন"])},
    "filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অন্বেষণ কোর্স"])},
    "filter_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["শ্রেণী"])},
    "filter_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্যাগ"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোর্সের ভাষা নির্বাচন করুন"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["শেখা শুরু করতে একটি কোর্স নির্বাচন করুন"])},
    "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আরও দেখুন"])}
  },
  "courses": {
    "navigation": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সাধারণ বিবরণ"])},
      "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাঠ"])},
      "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কুইজ"])}
    },
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দাম"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিনামূল্যে"])},
    "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["তালিকাভুক্ত করুন"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিস্তারিত দেখুন"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মিনিট"])},
    "other_videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অন্যান্য ভিডিও"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সম্পর্কে"])},
    "about_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আরো বিস্তারিত দেখতে একটি পাঠ নির্বাচন করুন"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সব"])}
  },
  "lessons": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বর্তমানে অধ্যয়নরত"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ভিডিও"])},
    "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কুইজ"])},
    "quiz_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কুইজ"])},
    "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["উপস্থাপনা"])},
    "presentation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোন উপস্থাপনা উপলব্ধ"])},
    "presentation_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাওয়ারপয়েন্ট ডাউনলোড করুন"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের সাথে যোগাযোগ করুন"])},
    "banner_1": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যোগাযোগ করুন"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আরও বাড়াতে আমাদের সাথে যোগাযোগ করুন"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইমেলের মাধ্যমে আমাদের সাথে যোগাযোগ করুন"])},
      "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ঠিকানা"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাঠান"])}
    },
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনার নাম"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যোগাযোগ"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইমেইল"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বার্তা"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাঠান"])}
    }
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["প্রোফাইল"])},
    "personal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ব্যক্তিগত"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ব্যক্তিগত বিবরণ"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নাম"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এনআরআইসি"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ফোন নম্বর"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইমেইল"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["গ্রুপ"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ডিফল্ট"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বৃদ্ধ"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অভিবাসী"])}
      }
    },
    "company": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোম্পানি"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোম্পানির বিবরণ"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোম্পানির নাম"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ভূমিকা"])}
    },
    "buttons": {
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপডেট"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মুছে দিন"])}
    },
    "courses": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোর্স"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোর্স নথিভুক্ত"])},
      "no_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি কোন কোর্সে নথিভুক্ত করা হয় না"])},
      "course_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোর্সের অগ্রগতি"])},
      "quiz_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কুইজের অগ্রগতি"])}
    },
    "assessments": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মূল্যায়ন"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মূল্যায়ন সম্পন্ন"])},
      "no_assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি কোনো মূল্যায়ন সম্পন্ন করেননি"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["স্ট্যাটাস"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["স্কোর"])},
      "view_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["উত্তর দেখুন"])}
    },
    "tutor": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টিউটর টুলস"])}
    }
  },
  "login": {
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সাইন ইন করুন"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইমেইল বা ফোন নম্বর"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাসওয়ার্ড"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লগইন করুন"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["একটি অ্যাকাউন্ট নেই? "])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নিবন্ধন করুন"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাসওয়ার্ড ভুলে গেছেন? "])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাসওয়ার্ড রিসেট করুন"])},
      "flavour3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অথবা দিয়ে সাইন ইন করুন"])},
      "hotline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নিচের অনুভূতি? এই হটলাইনে কল করুন: +65 3129 5000"])}
    },
    "register": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নিবন্ধন করুন"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ফোন নম্বর"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP পান"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইতিমধ্যে একটি অ্যাকাউন্ট আছে? "])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সাইন ইন করুন"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সাইন আপ করতে অক্ষম?"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এখানে আমাদের সাথে যোগাযোগ করুন"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাসওয়ার্ড রিসেট করুন"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইমেইল"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাঠান"])}
    },
    "warning": {
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google সাইন ইন এই মুহূর্তে উপলব্ধ নয়৷"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ফেসবুক সাইন ইন এই মুহূর্তে উপলব্ধ নয়."])}
    }
  },
  "navigation": {
    "header": {
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMU এবং SuperINK দ্বারা সমর্থিত"])},
      "languages": {
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইংরেজি"])},
        "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["চাইনিজ"])},
        "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মলয়"])},
        "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["তামিল"])},
        "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বার্মিজ"])},
        "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিংহলী"])},
        "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বাংলা"])}
      },
      "built_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seah Kim Cheok Construction Co. (Pte) Ltd দ্বারা নির্মিত"])}
    },
    "navbar": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বাড়ি"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের সম্পর্কে"])},
      "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোর্স"])},
      "shortcourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কুইজ"])},
      "character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["চরিত্র"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যোগাযোগ"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["প্রোফাইল"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লগইন/সাইন আপ করুন"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লগআউট"])},
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["স্বেচ্ছাসেবক"])}
    },
    "footer": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিনা খরচে নিজেদের ক্ষমতায়নের জন্য প্রত্যেককে এবং যেকোনও ব্যক্তিকে টুল এবং সংস্থান প্রদান করা।"])},
      "links": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লিঙ্ক"])}
      },
      "contact": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আমাদের সাথে যোগাযোগ করুন"])}
      },
      "connect": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দ্রুত সংযোগ"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নাম"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইমেইল"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বর্ণনা"])},
        "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাঠান"])}
      },
      "flavour": {
        "built": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দ্বারা নির্মিত"])},
        "supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দ্বারা সমর্থিত"])}
      }
    }
  },
  "extras": {
    "level1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এই কোর্সে, আপনি ইংরেজির মৌলিক বিষয়গুলি যেমন বর্ণমালা, সংখ্যা এবং ব্যাকরণ শিখতে শুরু করবেন।  এটি অনুসরণ করে, আপনি কীভাবে সহজ কথোপকথন করতে হয় তা শিখবেন যা আপনাকে আপনার পরিবার এবং বন্ধুদের সাথে যোগাযোগ করতে সহায়তা করবে। আপনি আরও সাধারণ পরিস্থিতিতে যেমন খাবারের অর্ডার দেওয়া এবং ডাক্তারের কাছে যাওয়ার মতো কথোপকথন করবেন তাও শিখবেন।"])},
    "level2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লেভেল 1 থেকে অবিরত, আপনি ইংরেজি ব্যাকরণ যেমন কাল, বিরাম চিহ্ন এবং সংযোজন সম্পর্কে আরও গভীরভাবে শিখবেন। এছাড়াও, আপনি ব্যক্তিগত এবং কাজের উভয় সেটিংসে আরও কথোপকথন দক্ষতা শিখবেন, যেমন আপনার অনুভূতি প্রকাশ করা, ইমেল লেখা এবং বিভিন্ন কাজের সাথে সম্পর্কিত পরিস্থিতিতে কথোপকথন করা।"])},
    "level3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লেভেল 3-এ পৌঁছে আমরা আরও জটিল বিষয় যেমন অব্যয়, মোডাল ক্রিয়াপদের মোকাবেলা করতে চলে যাই, তারপরে পড়া এবং বোঝার জন্য প্রয়োজনীয় দক্ষতা অর্জনে অগ্রসর হই। অবশেষে, আমরা একটি চূড়ান্ত পর্যালোচনা সেশনে আপনি যা শিখেছেন তা শেষ করব এবং আপনার ভবিষ্যত ইংরেজি অধ্যয়নে আপনাকে সাহায্য করার জন্য পরামর্শ দেব।"])}
  }
}