import axios from 'axios'

class LessonService {
    createLesson(data) {
        return axios.post(`${process.env.VUE_APP_APIURL}/lessons/`, data);
    }
    getLessons() {
        return axios.get(`${process.env.VUE_APP_APIURL}/lessons/`);
    }
    getLesson(id, languageCode) {
        return axios.get(`${process.env.VUE_APP_APIURL}/lessons/${id}${languageCode ? `?languageCode=${languageCode}` : ''}`);
    }
    updateLesson(id, data) {
        return axios.put(`${process.env.VUE_APP_APIURL}/lessons/${id}`, data);
    }
    deleteLesson(id) {
        if (!confirm("Are you sure you wish to delete this lesson?")) {
            return;
        }
        return axios.delete(`${process.env.VUE_APP_APIURL}/lessons/${id}`);
    }

    // Lesson Languages
    createLessonTranslation(data) {
        return axios.post(`${process.env.VUE_APP_APIURL}/lesson_languages/`, data);
    }
    getLessonTranslations(lessonId) {
        return axios.get(`${process.env.VUE_APP_APIURL}/lesson_languages/${lessonId}`);
    }
    getLessonTranslation(lessonId, languageCode) {
        return axios.get(`${process.env.VUE_APP_APIURL}/lesson_languages/${lessonId}${languageCode ? `?languageCode=${languageCode}` : ''}`);
    }
    updateLessonTranslation(id, data) {
        return axios.put(`${process.env.VUE_APP_APIURL}/lesson_languages/${id}`, data);
    }
    deleteLessonTranslation(id) {
        return axios.delete(`${process.env.VUE_APP_APIURL}/lesson_languages/${id}`);
    }

    // User Progress
    getUserProgress(userId, lessonId) {
        return axios.get(`${process.env.VUE_APP_APIURL}/user_progress/${userId}/${lessonId}`);
    }
    updateUserProgress(userId, lessonId, data) {
        return axios.put(`${process.env.VUE_APP_APIURL}/user_progress/${userId}/${lessonId}`, data);
    }
}

export default new LessonService();
