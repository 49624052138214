import { createStore } from 'vuex';

export default createStore({
  state: {
    user: JSON.parse(window.localStorage.getItem('user')) || null,
    isNavOpen: false
  },
  mutations: {
    setUserData(state, user) {
      state.user = user;
      window.localStorage.setItem('user', JSON.stringify(user));
    },
    toggleNav(state) {
      state.isNavOpen = !state.isNavOpen;
    }
  },
  actions: {
    async refreshUser() {
      try {
          const res = await this.getUser(this.getUserState().id);
          this.setUserState(res.data);
      } catch (error) {
          console.error("An error occurred while refreshing the user:", error);
          // Handle the error accordingly, perhaps show a user-friendly error message to the user
      }
  }
  },
});